import vaccineInformation from '@/components/documents/vaccineInformation'
import vaccineDescription from '@/components/documents/vaccineDescription'
import vaccinePolicy from '@/components/documents/vaccinePolicy'
import vaccineDisclaimer from '@/components/documents/vaccineDisclaimer'
import vaccineDisclosure from '@/components/documents/vaccineDisclosure'
import travelPolicy from "@/components/documents/travelPolicy";

import BottomButton from "@/components/BottomButton";
import { registerComponent } from "@/utils";
import { Tab, Tabs } from "vant";

export const components = registerComponent({
  BottomButton,
  Tab,
  Tabs,
  vaccineInformation,
  vaccineDescription,
  vaccinePolicy,
  vaccineDisclaimer,
  vaccineDisclosure,
  travelPolicy
});

export default { components };